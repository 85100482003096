<template>
  <svg class="fiveM" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><polygon  points="5,45 9,34 21,22 15,45"/><polygon  points="25,18 33,45 43,45 32,12"/><polygon  points="16.059,14.164 20,3 28,3"/><polygon  points="10.731,29.002 23,17 23,15 11.58,26.667"/><polygon  points="15.142,16.429 13,22 29.724,5.725 28.818,3.178"/><polygon  points="23.932,14.055 24.377,15.626 30.941,9.178 30.385,7.702"/></svg>
</template>

<script>
export default {
  name: 'FiveM.vue',
};
</script>

<style scoped lang="sass">
.fiveM
  fill: currentColor
</style>
