<template>
  <svg class="asa" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="48px" height="48px"><polygon points="24,12 24,12 24,12 11,37 13,39 14,41 15,41 13.511,38.021 24,16 35,39 37,37"/><polygon points="34,41 33,41 38,33 39,34"/><polygon points="29,7 29,9 24,16 19,9 19,7"/><polygon points="22.013,7 24,10 24,7"/><polygon points="24,10 27.229,10.463 25,13"/><polygon points="21.827,11.11 24,10 22.332,13.424"/><polygon points="19,7 20,7 24,16 19,9"/><polygon points="29,7 28,7 24,16 29,9"/><polygon points="18.043,12 17,16 20,16 20,13"/><polygon points="24,21 24,21 23,25 24,26"/><polygon points="42,32 37,23 35,24 35.826,21 28,9 25.181,14.347 36,37 34,41 37,41"/><polygon points="15.13,31 12,37 14,41 11,41 6,32 11.47,22.08 13,23 12.87,19.94 16.06,15.05 19,15 18.04,12 20,9 22.82,14.35 17.26,25 24,25 24,21 29,31"/><polygon points="11,41 1.83,41 3.42,38.14 4.05,37 5.04,35.21 5,32 6,32"/><polygon points="29,31 28,33 15,33 16.096,31"/><polygon points="10,41 0,41 1.59,38.14 2.22,37 3.21,35.21 3.68,34.37 5,32 6.68,35.02 7.03,35.65 8.17,37.7 8.17,37.71"/><polygon points="8.17,37.7 5,37 7.03,35.65"/><polygon points="5,37 2.22,37 3.21,35.21"/><polygon points="5,41 3,41 5,37"/><polygon points="37,41 46.17,41 44.58,38.14 43.95,37 42.96,35.21 43,32 42,32"/><polygon points="38,41 48,41 46.41,38.14 45.78,37 44.79,35.21 44.32,34.37 43,32 41.32,35.02 40.97,35.65 39.83,37.7 39.83,37.71"/><polygon points="39.83,37.7 43,37 40.97,35.65"/><polygon points="43,37 45.78,37 44.79,35.21"/><polygon points="43,41 45,41 43,37"/></svg></template>

<script>
export default {
  name: 'Asa.vue',
};
</script>

<style scoped lang="sass">
.asa
  fill: currentColor
</style>
