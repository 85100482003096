import { render, staticRenderFns } from "./EmailNotifications.vue?vue&type=template&id=5cf4454e&scoped=true&"
import script from "./EmailNotifications.vue?vue&type=script&lang=js&"
export * from "./EmailNotifications.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cf4454e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VCardText,VCardTitle,VIcon,VSwitch})
