<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" class="sevenDays" role="img"
       viewBox="0 0 900.000000 585.000000"
       preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,585.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
      <path d="M2940 5428 c0 -7 45 -152 98 -323 l98 -310 797 -3 c438 -1 797 -4
797 -6 0 -2 -11 -28 -25 -58 -17 -35 -23 -63 -19 -83 3 -19 -1 -37 -10 -50
-15 -20 -15 -20 -16 3 0 41 -17 22 -55 -65 -32 -70 -39 -97 -38 -142 1 -31 3
-58 3 -61 11 -37 -17 -170 -35 -170 -3 0 -3 4 0 10 3 5 1 18 -4 27 -8 14 -10
7 -10 -32 0 -90 -3 -109 -16 -129 -9 -12 -11 -25 -5 -36 9 -17 3 -60 -9 -60
-4 0 -11 4 -17 10 -6 6 -10 1 -12 -16 -2 -22 0 -24 13 -14 21 18 -35 -131 -57
-151 -19 -17 -25 -34 -8 -24 6 3 10 2 10 -3 0 -5 -14 -16 -31 -25 -17 -8 -26
-17 -20 -21 5 -4 8 -13 4 -21 -9 -24 11 -17 22 8 6 12 16 21 22 20 6 -1 14 9
17 22 4 14 10 25 15 25 5 0 12 23 16 52 7 50 8 51 21 27 16 -29 18 -65 4 -74
-6 -4 -8 -11 -4 -16 3 -5 0 -8 -7 -7 -7 2 -17 -6 -23 -18 -7 -13 -19 -20 -31
-18 -17 2 -18 1 -5 -9 16 -12 16 -47 0 -47 -5 0 -7 8 -3 18 6 19 -26 -12 -65
-62 -12 -16 -22 -25 -22 -20 0 5 7 18 15 28 21 27 19 35 -11 46 -23 9 -24 11
-10 26 18 17 22 54 6 54 -6 0 -10 -6 -9 -12 3 -24 -1 -38 -11 -38 -5 0 -10 16
-10 35 0 19 -3 35 -8 35 -4 0 -7 -21 -7 -47 1 -45 -20 -103 -37 -103 -5 0 -8
-8 -8 -18 0 -9 -11 -34 -25 -54 -28 -41 -32 -56 -11 -39 12 10 13 8 9 -8 -9
-35 5 -23 32 27 15 26 23 38 19 27 -3 -11 -9 -33 -13 -50 -4 -16 -11 -28 -16
-25 -6 3 -19 -13 -30 -35 -17 -34 -65 -94 -65 -81 0 2 7 20 16 41 8 20 13 42
10 48 -3 9 -5 8 -5 0 -1 -7 -5 -13 -11 -13 -5 0 -7 7 -4 15 4 8 1 15 -5 15 -6
0 -12 -8 -13 -17 -1 -10 -2 -25 -3 -33 -1 -8 -7 -25 -14 -38 -6 -12 -9 -27 -6
-32 3 -5 2 -17 -3 -26 -7 -13 -11 -9 -16 19 -4 20 -10 39 -15 41 -4 3 -6 -6
-3 -19 2 -14 -1 -31 -7 -39 -8 -10 -9 -16 -1 -21 6 -3 8 -10 5 -15 -3 -5 -1
-11 4 -14 4 -3 11 -24 15 -46 4 -24 11 -38 17 -35 5 4 7 21 3 40 -4 21 -1 35
6 40 9 5 10 1 5 -16 -7 -22 -6 -22 9 -10 9 8 15 19 12 26 -2 6 11 24 30 40 18
15 31 32 28 37 -11 17 19 80 53 114 l35 35 -7 -30 c-4 -18 -3 -31 3 -31 6 0 8
-9 5 -20 -3 -11 -12 -20 -19 -20 -8 0 -14 -7 -14 -15 0 -8 3 -15 6 -15 12 0
53 38 47 43 -3 4 6 25 21 47 14 22 26 44 26 50 0 5 7 10 16 10 8 0 13 4 10 9
-3 4 1 17 9 27 13 17 14 15 11 -26 -2 -34 -6 -44 -18 -42 -8 1 -27 -14 -42
-33 -15 -19 -22 -35 -17 -35 7 0 7 -8 2 -22 -5 -13 -14 -22 -19 -21 -6 2 -20
-11 -32 -27 -12 -17 -17 -30 -11 -30 7 0 7 -8 0 -27 -14 -36 -40 -65 -54 -59
-7 2 -12 -1 -12 -7 -1 -7 -4 -27 -8 -44 -5 -23 -4 -33 4 -33 7 0 9 5 6 10 -3
6 0 13 9 16 8 3 13 13 10 22 -3 9 1 6 9 -7 8 -14 13 -31 10 -38 -6 -16 -47
-28 -62 -18 -6 3 -15 -5 -20 -19 -6 -13 -16 -28 -24 -33 -8 -4 -16 -18 -19
-30 -4 -16 -1 -21 12 -20 10 1 16 7 13 14 -6 14 14 43 29 43 5 0 4 -9 -2 -20
-9 -16 -8 -18 5 -14 10 4 15 0 15 -15 0 -13 -5 -19 -14 -15 -8 3 -17 0 -21 -5
-3 -6 -10 -9 -15 -6 -4 3 -14 -3 -21 -12 -12 -15 -12 -16 4 -10 13 5 17 3 14
-5 -2 -7 -11 -13 -19 -13 -8 0 -22 -6 -30 -12 -10 -9 -18 -9 -25 -2 -7 7 -12
3 -17 -14 -3 -14 -13 -28 -22 -31 -12 -5 -14 -1 -9 17 6 21 5 21 -9 3 -13 -16
-12 -20 11 -40 l26 -22 -24 -37 c-17 -26 -29 -35 -41 -31 -10 3 -18 1 -18 -4
0 -15 32 -24 40 -12 4 7 15 7 29 2 22 -8 21 -9 -13 -27 -19 -10 -37 -29 -41
-42 -5 -22 -25 -33 -25 -13 0 5 -7 7 -15 4 -8 -4 -15 -13 -15 -21 0 -9 6 -13
14 -10 17 7 15 -8 -7 -52 -9 -19 -17 -40 -17 -48 0 -7 -6 -13 -12 -13 -9 0
-10 -3 -2 -8 12 -8 13 -90 1 -109 -4 -6 -4 -18 -1 -26 4 -11 -4 -21 -24 -31
-16 -8 -31 -13 -34 -11 -2 2 -4 -5 -5 -15 -1 -14 5 -20 20 -20 13 0 17 -3 11
-8 -6 -4 -11 -14 -11 -22 -1 -8 -8 -32 -17 -52 -16 -38 -6 -53 14 -20 9 15 10
14 5 -6 -7 -25 12 -27 31 -3 9 11 12 11 18 -4 4 -12 1 -22 -9 -27 -8 -5 -15
-19 -15 -31 0 -18 -2 -19 -10 -7 -9 13 -11 13 -20 0 -15 -23 -12 -29 12 -25
13 3 31 15 40 27 10 13 18 18 18 12 0 -6 -11 -21 -25 -34 -13 -12 -22 -27 -19
-32 10 -16 -39 -47 -58 -37 -23 12 -28 11 -22 -5 3 -8 15 -17 27 -20 17 -5 19
-8 8 -15 -15 -10 -5 -35 11 -25 6 4 6 -1 -1 -14 -6 -11 -11 -31 -10 -44 0 -13
3 -17 6 -10 6 15 23 16 23 2 0 -6 -11 -15 -25 -20 -14 -5 -25 -15 -25 -21 0
-8 4 -8 15 1 13 11 14 10 9 -3 -3 -9 -14 -19 -25 -22 -10 -4 -19 -14 -19 -23
1 -16 2 -16 14 0 16 22 46 24 46 3 0 -8 -4 -15 -10 -15 -5 0 -10 -6 -10 -14 0
-7 -7 -20 -17 -27 -15 -13 -15 -12 -4 9 6 12 8 22 3 22 -15 0 -32 -24 -32 -47
0 -22 -9 -27 -37 -24 -7 1 -17 -8 -24 -20 -10 -19 -9 -21 5 -16 18 7 43 -28
26 -38 -5 -3 -14 -4 -18 -1 -4 2 -8 -4 -8 -14 0 -10 3 -17 6 -15 4 2 7 -2 7
-9 0 -8 -6 -12 -14 -9 -7 3 -13 -2 -13 -10 0 -8 -4 -17 -9 -19 -5 -1 -11 -10
-13 -18 -2 -8 -13 -25 -25 -37 -22 -21 -31 -39 -15 -29 4 2 14 0 22 -6 12 -8
12 -9 -3 -4 -11 3 -20 0 -23 -9 -4 -8 -10 -12 -15 -9 -5 3 -7 -5 -6 -18 5 -30
37 -37 44 -9 6 24 23 29 23 7 0 -8 -5 -27 -10 -42 -7 -17 -7 -30 -1 -33 5 -4
9 -15 8 -26 -1 -13 4 -20 15 -19 10 1 14 -4 11 -12 -3 -8 -2 -14 1 -14 4 0 11
11 16 25 5 14 14 25 19 25 4 0 1 -16 -7 -35 -10 -22 -21 -34 -30 -30 -9 3 -11
-1 -7 -15 4 -11 2 -20 -4 -20 -6 0 -7 -8 -4 -18 6 -14 4 -15 -9 -4 -13 10 -16
9 -21 -9 -4 -15 -2 -19 9 -15 24 9 16 -11 -13 -33 -16 -11 -22 -21 -15 -21 19
0 14 -16 -13 -46 l-25 -27 23 -12 c26 -13 59 -16 51 -4 -3 5 2 21 10 37 8 16
13 32 10 35 -8 7 25 68 47 90 13 12 17 26 13 42 -5 19 -2 25 11 25 9 0 13 4
10 10 -3 5 0 20 7 32 7 13 11 26 10 31 -1 4 2 7 7 7 5 0 11 10 13 23 2 15 9
21 21 19 10 -2 17 3 17 13 0 8 -5 15 -11 15 -6 0 -7 5 -2 13 4 6 8 19 10 27 1
8 13 24 27 35 30 24 33 35 9 35 -21 0 -11 11 14 14 24 4 53 54 36 64 -8 5 -4
16 13 35 13 16 24 25 24 21 0 -4 6 1 14 11 9 13 11 25 5 36 -6 12 -3 21 13 31
17 11 21 23 20 55 -1 27 4 45 15 54 15 13 15 12 4 -8 -8 -16 -8 -23 -1 -23 6
0 13 7 16 15 4 8 10 15 15 15 4 0 6 -7 3 -15 -6 -15 4 -19 39 -16 22 3 22 -2
-7 -48 -14 -21 -28 -55 -31 -74 -7 -36 -55 -83 -55 -55 0 7 -5 5 -11 -5 -6
-10 -8 -21 -5 -24 3 -4 6 -1 6 5 0 6 6 9 14 6 11 -4 9 -16 -9 -59 -12 -29 -27
-60 -33 -68 -7 -7 -9 -16 -6 -20 3 -3 -2 -19 -12 -36 -13 -23 -24 -30 -39 -26
-11 3 -15 2 -8 -1 7 -3 9 -12 6 -20 -3 -8 -1 -14 5 -14 11 0 -27 -82 -40 -88
-5 -2 -8 -11 -8 -20 0 -9 -10 -33 -22 -52 -12 -19 -22 -44 -24 -55 -4 -34 -58
-145 -71 -145 -6 0 -9 -8 -6 -19 3 -11 -19 -68 -51 -134 -31 -63 -56 -119 -56
-125 0 -5 -9 -29 -19 -53 -11 -24 -17 -47 -15 -51 3 -4 1 -8 -4 -8 -4 0 -25
-35 -46 -77 -21 -43 -42 -86 -47 -95 -15 -29 -10 -43 9 -22 27 30 48 66 61
107 7 20 17 37 23 37 7 0 7 4 0 13 -8 9 -4 15 15 24 25 12 25 12 18 -30 -4
-31 -3 -38 4 -27 6 8 11 32 11 53 0 21 4 46 9 56 6 10 12 31 15 47 2 16 5 31
5 34 1 3 5 -2 11 -10 5 -8 12 -11 16 -7 4 3 1 13 -5 21 -20 24 6 29 29 6 12
-12 23 -20 26 -18 2 2 15 33 29 69 22 56 25 59 20 24 l-6 -40 24 28 c14 15 30
42 37 60 27 73 45 112 53 118 5 3 8 9 8 13 -1 17 13 36 28 37 12 0 12 2 -4 9
-11 5 -14 9 -7 9 6 1 12 7 12 15 0 8 9 31 20 53 32 62 100 225 101 242 1 9 4
5 9 -9 7 -25 7 -25 23 10 8 19 12 37 7 40 -13 8 24 47 40 43 13 -4 13 -3 1 5
-11 8 -12 14 -4 24 18 22 25 98 9 104 -11 4 -4 16 23 44 37 38 67 46 99 26 15
-9 15 -1 1 26 -9 16 -7 23 10 35 12 8 21 18 21 23 0 5 9 24 21 42 16 25 19 27
14 9 -5 -17 -4 -22 6 -19 19 6 24 48 7 48 -9 0 -9 3 -2 8 6 4 16 17 23 30 10
19 15 21 32 11 24 -13 35 -5 44 34 7 26 0 33 -27 28 -10 -1 -9 8 2 34 7 17 9
17 9 3 1 -10 5 -18 10 -18 17 0 21 33 6 56 -12 19 -13 28 -3 49 6 14 17 25 24
25 7 0 18 8 24 18 8 13 9 9 4 -19 -4 -24 -2 -39 7 -44 8 -5 10 -4 5 4 -4 6 1
29 13 50 11 22 18 41 15 44 -2 2 0 16 5 31 7 16 7 26 1 26 -5 0 -7 7 -4 15 4
8 1 15 -6 15 -8 0 -10 -9 -5 -27 5 -23 4 -25 -9 -14 -16 14 -15 19 16 62 18
24 16 59 -3 59 -20 0 -7 18 27 36 20 10 42 33 49 49 9 20 14 24 14 12 1 -10 6
-15 11 -12 7 4 7 13 0 24 -13 26 34 68 56 50 20 -16 28 -5 27 36 -1 19 3 33 7
30 5 -3 7 -21 5 -40 -3 -35 8 -54 20 -35 4 6 11 7 17 4 7 -5 8 -2 3 6 -5 8 -2
17 6 24 8 6 21 25 28 42 7 17 22 37 34 43 12 7 32 34 45 61 12 28 33 57 47 66
14 9 25 24 25 34 0 10 11 32 25 48 13 16 30 50 37 76 11 42 11 49 -4 57 -10 5
-15 16 -12 24 11 28 -25 15 -43 -15 -9 -16 -23 -30 -30 -30 -7 0 -19 -15 -27
-32 -11 -29 -57 -78 -71 -78 -7 0 38 74 50 82 7 5 7 8 0 8 -6 0 -17 -6 -23
-12 -7 -7 -17 -13 -22 -13 -5 0 -16 -9 -25 -20 -17 -20 -17 -20 -10 0 25 72
27 89 11 101 -20 14 -20 53 -1 78 8 10 11 24 8 30 -4 6 1 5 10 -3 18 -15 23
-3 7 17 -7 9 -7 9 2 2 22 -18 30 -10 24 23 -4 20 -1 39 6 48 7 8 12 12 11 10
-2 -2 2 -10 8 -18 9 -9 8 -13 0 -13 -6 0 -11 -5 -11 -10 0 -6 8 -5 19 2 11 7
17 16 14 21 -3 5 4 6 16 2 13 -4 21 -2 21 5 0 6 5 8 12 4 8 -5 9 0 5 16 -6 24
19 74 33 65 4 -3 13 2 20 10 8 9 8 15 2 15 -6 0 -13 -6 -15 -12 -4 -8 -6 -7
-6 3 -1 9 8 19 20 22 13 4 18 10 14 17 -4 7 -3 15 2 19 6 3 13 19 17 35 7 29
7 29 -17 13 -24 -16 -25 -15 -29 14 -5 32 6 49 16 24 9 -24 26 -17 26 10 0 14
5 25 11 25 6 0 3 7 -5 16 -9 8 -20 12 -26 9 -16 -10 -12 7 10 43 17 27 24 31
35 22 16 -13 9 -40 -11 -40 -8 0 -14 -4 -14 -10 0 -5 5 -10 11 -10 14 0 39 26
39 40 0 17 -36 61 -44 53 -3 -3 -6 -1 -6 6 0 6 7 14 15 17 12 5 13 9 4 20 -10
12 -9 13 5 8 17 -7 23 11 6 21 -12 7 -2 63 17 93 7 12 18 20 23 17 5 -3 12 0
16 6 5 8 3 9 -6 4 -10 -6 -12 -4 -7 9 4 10 10 29 13 42 6 24 24 34 24 13 0 -8
5 -8 15 1 22 18 20 54 -2 46 -16 -6 -16 -6 0 11 9 10 23 34 31 53 14 32 15 33
17 10 1 -14 0 -29 -1 -35 -1 -5 -4 -22 -6 -37 -4 -31 13 -48 23 -23 3 10 1 20
-5 24 -7 4 -5 13 5 26 15 20 15 20 10 -3 -3 -14 -1 -22 5 -20 12 4 39 78 28
78 -4 0 -10 10 -13 21 -4 15 -11 20 -21 16 -11 -4 -16 -1 -16 12 0 27 11 33
31 15 20 -18 30 -7 22 24 -6 24 -22 30 -24 10 0 -7 -5 0 -9 16 -11 35 3 66 30
66 10 0 26 7 34 16 15 14 16 14 16 -5 0 -23 25 -29 33 -8 3 7 6 4 6 -6 1 -10
-4 -27 -9 -38 -6 -10 -7 -19 -2 -19 4 0 19 26 32 58 13 31 33 80 46 107 33 74
35 85 18 78 -13 -5 -18 -17 -15 -36 2 -14 -51 -127 -60 -127 -5 0 -9 4 -9 9 0
5 -3 16 -6 25 -4 10 -1 16 9 16 15 0 47 56 47 82 0 8 7 23 15 34 8 10 15 26
15 35 0 8 9 25 20 37 11 12 20 25 20 29 0 4 32 70 71 146 43 84 68 144 65 155
-7 20 59 162 80 175 8 4 14 13 14 20 0 7 18 48 40 92 22 44 40 86 40 93 0 8
28 80 61 160 34 81 63 153 66 159 4 10 -305 13 -1496 13 -1195 0 -1501 -3
-1501 -12z m1711 -891 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z
m-31 -90 c0 -12 -5 -29 -10 -37 -7 -11 -10 -3 -10 28 0 26 4 41 10 37 6 -3 10
-16 10 -28z m587 -89 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m212 -45 c-24 -70 -38 -88 -40 -50 -1 15 -2 32 -3 37 -1 6 3 10 8 9 5 0 15 13
24 30 25 52 32 35 11 -26z m-489 8 c0 -5 -7 -14 -15 -21 -19 -16 -19 -34 0
-27 11 5 12 1 5 -19 -13 -33 -13 -34 5 -28 10 4 15 0 15 -11 0 -12 5 -15 21
-10 15 5 20 3 17 -6 -3 -8 -13 -15 -24 -16 -10 -1 -28 -3 -39 -5 -16 -2 -21 4
-23 32 -4 42 -19 61 -32 40 -6 -9 -5 -17 3 -22 9 -5 9 -10 0 -19 -18 -18 -26
-5 -18 29 3 16 17 35 30 42 14 7 27 21 31 32 5 18 24 25 24 9z m440 -81 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-400 -90
c-10 -19 -30 -28 -30 -14 0 6 30 33 38 34 1 0 -2 -9 -8 -20z m-176 -51 c-10
-23 -20 -37 -22 -30 -4 12 29 84 35 77 2 -2 -4 -23 -13 -47z m90 15 c4 -9 4
-19 1 -22 -2 -3 -7 3 -11 12 -9 22 -21 20 -29 -5 -5 -16 -10 -18 -18 -9 -9 8
-7 15 7 26 26 18 42 18 50 -2z m-207 -104 c-5 -27 -13 -48 -16 -45 -4 2 -13 0
-21 -5 -11 -7 -11 -12 -2 -23 10 -12 8 -16 -10 -21 -13 -3 -35 -6 -50 -6 -16
0 -28 -4 -28 -10 0 -5 6 -10 13 -10 8 0 13 -9 11 -22 -1 -13 -3 -23 -5 -23 -1
0 -3 -19 -4 -43 -1 -24 -10 -51 -20 -62 -10 -11 -15 -20 -11 -20 4 0 4 -5 1
-10 -4 -6 -11 -8 -16 -4 -6 4 -6 10 0 18 6 7 8 15 5 18 -3 4 2 21 11 39 16 29
16 33 1 41 -21 12 -20 26 4 74 25 50 44 74 58 74 6 0 9 6 6 14 -3 9 4 15 20
18 14 3 26 1 26 -4 0 -5 5 -6 11 -3 6 4 8 14 5 22 -5 12 9 43 19 43 1 0 -2
-22 -8 -50z m169 23 c-10 -11 -19 -7 -22 10 -2 15 0 16 12 6 8 -6 12 -14 10
-16z m-111 -12 c-9 -16 15 -24 31 -10 12 10 13 9 8 -5 -3 -9 -12 -16 -20 -16
-8 0 -14 -6 -14 -14 0 -8 -5 -18 -12 -22 -7 -5 -9 -2 -5 9 4 11 2 14 -8 10
-20 -7 -31 25 -15 40 10 10 12 8 7 -5 -4 -12 -1 -18 9 -18 11 0 13 6 9 20 -4
12 -2 20 5 20 6 0 9 -4 5 -9z m325 -45 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12
-4 4 13 16 21 21 21 13z m-350 -34 c0 -17 -49 -66 -57 -58 -4 5 2 15 15 23 12
9 22 22 22 29 0 8 5 14 10 14 6 0 10 -4 10 -8z m84 -17 c-7 -16 -24 -21 -24
-6 0 8 7 13 27 20 1 1 0 -6 -3 -14z m51 -35 c-3 -5 -11 -10 -16 -10 -6 0 -7 5
-4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m130 -19 c3 -5 0 -13 -7 -17 -6 -4 -14
-2 -16 4 -2 7 -9 9 -14 6 -7 -4 -8 -2 -4 4 9 14 33 16 41 3z m-242 -41 c-8
-17 -19 -28 -23 -25 -5 3 0 17 12 30 28 32 30 31 11 -5z m-61 -20 c-5 -29 -28
-54 -37 -40 -3 5 -1 10 5 10 8 0 10 9 6 24 -6 25 1 39 18 33 6 -2 9 -14 8 -27z
m-351 -267 c-5 -10 -17 -27 -26 -38 -16 -19 -16 -19 -4 5 13 27 29 50 36 50 2
0 -1 -8 -6 -17z m59 13 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10
11 6 0 10 -2 10 -4z m-160 -489 c0 -5 -4 -5 -10 -2 -5 3 -10 14 -10 23 0 15 2
15 10 2 5 -8 10 -19 10 -23z m-50 -81 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12
-4 4 13 16 21 21 21 13z m-91 -103 c-7 -7 -9 -16 -6 -20 9 -9 -22 -63 -36 -63
-9 0 -9 2 1 8 7 5 14 23 16 40 3 17 8 37 12 43 5 9 2 10 -11 5 -15 -5 -16 -4
-5 9 10 12 16 13 26 4 11 -9 12 -15 3 -26z m24 -23 c-3 -12 -8 -19 -11 -16 -5
6 5 36 12 36 2 0 2 -9 -1 -20z m-73 -160 c0 -5 -7 -10 -15 -10 -8 0 -15 -7
-15 -16 0 -8 -4 -12 -10 -9 -5 3 -10 15 -10 26 0 14 7 19 25 19 14 0 25 -4 25
-10z m790 -85 c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10
-19 10 -25z m-420 -25 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10
6 0 11 -4 11 -10z m390 -30 c-6 -11 -13 -20 -16 -20 -2 0 0 9 6 20 6 11 13 20
16 20 2 0 0 -9 -6 -20z m-250 -170 c0 -11 -5 -22 -11 -26 -8 -5 -8 -9 1 -14
20 -12 15 -55 -9 -79 -25 -25 -28 -11 -5 24 12 19 13 28 4 45 -9 16 -8 25 1
36 8 10 8 14 0 14 -6 0 -11 7 -11 15 0 9 6 12 15 9 8 -4 15 -14 15 -24z m-666
-354 c2 -14 -2 -27 -9 -30 -9 -3 -12 5 -10 27 4 41 4 42 11 35 3 -4 7 -18 8
-32z m20 -57 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10
3 -15z m289 -4 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m-43
-44 c0 -5 -9 -11 -21 -14 -16 -4 -18 -3 -8 9 13 16 29 19 29 5z m0 -36 c-7 -9
-15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z m-139 -82 c-8 -15 -10 -15
-11 -2 0 17 10 32 18 25 2 -3 -1 -13 -7 -23z m191 -65 c-7 -7 -12 -8 -12 -2 0
14 12 26 19 19 2 -3 -1 -11 -7 -17z m-412 -16 c0 -13 -12 -22 -22 -16 -10 6
-1 24 13 24 5 0 9 -4 9 -8z m23 -46 c-6 -10 -13 -12 -23 -6 -13 8 -12 10 3 10
9 0 17 5 17 12 0 6 3 9 6 5 3 -3 2 -12 -3 -21z m67 -70 c0 -3 -4 -8 -10 -11
-5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-146 -171 c3 -8 1 -15
-3 -15 -5 0 -11 7 -15 15 -3 8 -1 15 3 15 5 0 11 -7 15 -15z m166 -342 c-11
-17 -11 -17 -6 0 3 10 6 24 7 30 0 9 2 9 5 0 3 -7 0 -20 -6 -30z m13 -28 c-3
-9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m-120 -180 c-3 -9 -8 -14
-10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z"/>
      <path d="M4900 4195 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
      <path d="M4536 3854 c-5 -12 -2 -15 10 -10 8 3 18 6 20 6 3 0 2 5 -1 10 -9 15
-22 12 -29 -6z"/>
      <path d="M5590 4389 c-22 -18 -22 -19 -3 -10 12 6 25 16 28 21 9 15 3 12 -25
-11z"/>
      <path d="M5685 4290 c-27 -30 -12 -34 15 -5 13 14 19 25 13 25 -5 0 -18 -9
-28 -20z"/>
      <path d="M5485 4270 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M4540 4234 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
      <path d="M5765 4240 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M4477 4213 c-13 -21 -20 -123 -8 -123 10 0 15 14 12 33 -1 4 3 16 8
26 11 20 -1 82 -12 64z"/>
      <path d="M4260 4195 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
      <path d="M4310 4200 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
      <path d="M4300 4130 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
      <path d="M5559 4123 c-13 -15 -12 -15 9 -4 23 12 28 21 13 21 -5 0 -15 -7 -22
-17z"/>
      <path d="M4260 4120 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
      <path d="M5266 4104 c-5 -14 -4 -15 9 -4 17 14 19 20 6 20 -5 0 -12 -7 -15
-16z"/>
      <path d="M4286 4074 c-4 -10 -1 -14 6 -12 15 5 23 28 10 28 -5 0 -13 -7 -16
-16z"/>
      <path d="M5310 4049 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M4207 4043 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
      <path d="M4257 4030 c-23 -27 -49 -80 -40 -81 5 0 18 -2 31 -5 12 -2 22 1 22
7 0 5 -4 8 -9 5 -19 -12 -19 23 0 54 23 37 21 49 -4 20z"/>
      <path d="M4382 4028 c-36 -47 -46 -118 -16 -118 18 0 22 11 28 78 5 56 4 59
-12 40z"/>
      <path d="M5420 4020 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
      <path d="M4410 3996 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
      <path d="M4255 3980 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M5320 3950 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
      <path d="M4253 3904 c0 -16 13 -19 21 -5 3 5 0 11 -7 14 -8 3 -14 -1 -14 -9z"/>
      <path d="M4400 3902 c0 -4 9 -15 20 -25 19 -16 20 -16 20 2 0 10 -7 22 -16 25
-20 8 -24 8 -24 -2z"/>
      <path d="M5310 3899 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M4195 3890 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M4210 3859 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M5277 3846 c-3 -8 -2 -18 3 -21 13 -8 24 15 12 27 -6 6 -11 4 -15 -6z"/>
      <path d="M4390 3840 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
      <path d="M4422 3838 c-14 -14 -16 -42 -2 -33 14 9 32 45 22 45 -4 0 -13 -5
-20 -12z"/>
      <path d="M5405 3840 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
      <path d="M4194 3822 c1 -19 16 -33 28 -26 14 9 9 23 -11 33 -15 9 -18 7 -17
-7z"/>
      <path d="M4261 3835 c0 -5 3 -16 8 -23 6 -10 11 -9 22 4 12 16 12 17 -3 11 -9
-3 -19 -1 -22 6 -2 7 -5 8 -5 2z"/>
      <path d="M5204 3800 c0 -11 5 -19 11 -17 5 2 10 10 10 17 0 7 -5 15 -10 17 -6
2 -11 -6 -11 -17z"/>
      <path d="M5155 3800 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
      <path d="M5255 3790 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
      <path d="M4235 3780 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M4197 3763 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
      <path d="M4340 3740 c0 -16 17 -20 28 -7 8 10 6 13 -9 14 -10 1 -19 -2 -19 -7z"/>
      <path d="M4066 3731 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
      <path d="M4225 3730 c-3 -5 -3 -15 1 -21 6 -11 23 6 24 24 0 10 -18 9 -25 -3z"/>
      <path d="M5327 3733 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4 20z"/>
      <path d="M4160 3715 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z"/>
      <path d="M5234 3685 c-9 -13 -14 -30 -11 -37 4 -8 6 -7 6 4 1 10 11 26 23 38
12 11 17 20 10 20 -6 0 -19 -11 -28 -25z"/>
      <path d="M4146 3672 c-2 -4 4 -8 14 -8 10 0 16 4 14 8 -3 4 -9 8 -14 8 -5 0
-11 -4 -14 -8z"/>
      <path d="M4230 3669 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M5250 3660 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
      <path d="M4163 3623 c-19 -39 -17 -59 2 -43 8 7 15 19 15 26 0 8 7 11 18 8 16
-5 16 -5 -1 6 -9 6 -15 15 -12 21 4 5 3 9 -2 9 -4 0 -14 -12 -20 -27z"/>
      <path d="M5280 3639 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M4120 3625 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
      <path d="M5184 3619 c-7 -12 15 -31 28 -23 4 3 8 12 8 20 0 17 -26 19 -36 3z"/>
      <path d="M5116 3601 c-15 -17 -15 -19 0 -32 16 -13 17 -12 12 9 -4 15 -2 22 8
22 8 0 14 5 14 10 0 16 -15 12 -34 -9z"/>
      <path d="M4170 3575 c-7 -8 -10 -21 -7 -29 3 -7 0 -19 -6 -25 -6 -6 -8 -14 -4
-18 13 -12 31 17 34 52 2 39 1 41 -17 20z"/>
      <path d="M5127 3523 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
      <path d="M5180 3500 c-9 -22 -16 -28 -23 -21 -19 19 -29 12 -22 -16 11 -46 50
-77 44 -35 -2 9 3 34 10 55 16 47 9 60 -9 17z"/>
      <path d="M4110 3489 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M5347 3493 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
      <path d="M4067 3463 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
      <path d="M5190 3440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
      <path d="M4090 3419 c-15 -28 -4 -38 15 -13 17 22 19 34 5 34 -5 0 -14 -9 -20
-21z"/>
      <path d="M5080 3425 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z"/>
      <path d="M5115 3420 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M4255 3400 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
      <path d="M5110 3390 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
      <path d="M5180 3370 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
      <path d="M4215 3340 c-3 -5 -3 -15 1 -21 6 -11 23 6 24 24 0 10 -18 9 -25 -3z"/>
      <path d="M5070 3340 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
      <path d="M4015 3330 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M5160 3315 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
      <path d="M4200 3295 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
      <path d="M4172 3248 c2 -6 10 -14 16 -16 7 -2 10 2 6 12 -7 18 -28 22 -22 4z"/>
      <path d="M5185 3240 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M3980 3215 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
      <path d="M4010 3190 c-12 -8 -11 -10 8 -10 12 0 22 5 22 10 0 13 -11 13 -30 0z"/>
      <path d="M4030 3160 c-20 -16 -22 -22 -11 -29 8 -5 12 -18 8 -30 -10 -38 10
-22 27 22 24 65 20 72 -24 37z"/>
      <path d="M3944 3124 c1 -26 11 -32 19 -11 2 6 -1 17 -8 24 -10 9 -13 7 -11
-13z"/>
      <path d="M4105 3130 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
      <path d="M3987 3093 c-20 -23 -23 -40 -13 -81 7 -31 34 -41 28 -11 -1 8 7 3
18 -10 24 -28 37 -22 20 9 -9 18 -9 24 0 30 7 4 9 13 5 20 -6 10 -11 9 -21 -4
-8 -12 -14 -14 -19 -5 -4 6 -3 14 2 18 10 7 12 51 2 51 -3 0 -13 -8 -22 -17z"/>
      <path d="M3935 3050 c-4 -11 -10 -18 -15 -15 -5 3 -14 -4 -20 -15 -6 -11 -7
-20 -3 -20 12 0 63 49 63 60 0 18 -19 10 -25 -10z"/>
      <path d="M4884 3055 c-4 -8 -11 -12 -17 -8 -6 3 -7 1 -3 -5 9 -15 36 -4 36 14
0 18 -9 18 -16 -1z"/>
      <path d="M4061 3041 c-14 -25 -1 -35 15 -13 16 20 17 32 4 32 -5 0 -14 -8 -19
-19z"/>
      <path d="M4820 3030 c0 -6 8 -14 18 -17 25 -9 32 -24 21 -44 -11 -22 -11 -29
0 -29 5 0 14 14 20 30 9 28 8 32 -14 42 -14 6 -25 15 -25 20 0 4 -4 8 -10 8
-5 0 -10 -5 -10 -10z"/>
      <path d="M3748 3013 c6 -2 10 -9 6 -14 -3 -5 -1 -9 5 -9 17 0 21 18 5 24 -9 3
-19 6 -22 5 -4 0 -1 -3 6 -6z"/>
      <path d="M3950 2999 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M4076 2985 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
      <path d="M4910 2979 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M3892 2926 c-19 -19 -29 -36 -24 -40 6 -3 14 -1 18 5 5 8 8 8 14 -1
5 -8 9 -7 13 5 4 9 3 13 -3 10 -6 -3 -10 -1 -10 5 0 6 6 13 14 16 15 6 30 34
18 34 -4 0 -22 -16 -40 -34z"/>
      <path d="M3856 2844 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z"/>
      <path d="M4950 2714 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
      <path d="M3845 2630 c-8 -9 -15 -22 -15 -28 0 -20 23 -13 57 16 l32 29 -30 0
c-16 0 -36 -8 -44 -17z"/>
      <path d="M3800 2599 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M4826 2575 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
      <path d="M4915 2493 l-17 -28 23 22 c12 12 20 25 17 28 -3 3 -13 -7 -23 -22z"/>
      <path d="M3824 2475 c-16 -12 -16 -14 -3 -15 9 0 22 7 29 15 16 19 0 19 -26 0z"/>
      <path d="M4676 2482 c-2 -4 4 -13 14 -20 12 -7 21 -8 25 -2 3 6 -1 10 -10 10
-9 0 -13 5 -10 10 3 6 2 10 -4 10 -5 0 -12 -4 -15 -8z"/>
      <path d="M3775 2470 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
      <path d="M4727 2423 c-10 -18 -26 -37 -37 -43 -11 -6 -20 -18 -20 -28 0 -17
-38 -48 -53 -44 -5 1 -6 -6 -3 -16 4 -10 0 -35 -9 -55 -11 -26 -12 -38 -3 -43
7 -4 8 -3 4 4 -4 7 -2 12 3 12 11 0 15 -13 12 -37 -4 -33 63 19 74 57 4 14 16
36 26 49 24 30 52 123 40 130 -5 3 -11 15 -13 26 -3 17 -6 16 -21 -12z"/>
      <path d="M3777 2426 c-4 -10 -5 -21 -2 -24 9 -9 17 6 13 25 -3 17 -4 17 -11
-1z"/>
      <path d="M4680 2419 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M4644 2403 c-3 -10 -10 -28 -16 -40 -6 -11 -8 -27 -5 -35 4 -8 6 -6
6 6 1 10 9 24 18 31 17 13 26 55 11 55 -5 0 -11 -8 -14 -17z"/>
      <path d="M4785 2399 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z"/>
      <path d="M3745 2280 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M3790 2245 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
      <path d="M3754 2171 c-10 -10 -27 -28 -36 -40 -17 -20 -17 -21 2 -31 15 -8 23
-5 39 16 12 15 21 37 21 50 0 29 -1 29 -26 5z"/>
      <path d="M3800 2179 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M3626 2114 c-4 -10 -1 -14 6 -12 15 5 23 28 10 28 -5 0 -13 -7 -16
-16z"/>
      <path d="M4540 2104 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
      <path d="M3826 2097 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
      <path d="M3465 2080 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
      <path d="M3657 2084 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23
-2 6 -7 8 -11 4z"/>
      <path d="M4480 2070 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"/>
      <path d="M3624 2044 c4 -9 1 -23 -6 -32 -12 -14 -11 -14 10 -1 23 15 29 29 11
29 -5 0 -7 5 -4 10 3 6 1 10 -5 10 -7 0 -9 -7 -6 -16z"/>
      <path d="M3710 2049 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M4510 2045 c-10 -12 -10 -15 3 -15 8 0 17 7 21 15 3 8 2 15 -3 15 -5
0 -14 -7 -21 -15z"/>
      <path d="M4550 2048 c0 -9 5 -20 11 -23 17 -11 20 0 4 21 -13 17 -14 17 -15 2z"/>
      <path d="M3691 2016 c-8 -9 -11 -19 -7 -23 9 -9 29 13 24 27 -2 8 -8 7 -17 -4z"/>
      <path d="M3746 1998 c-18 -58 -12 -74 14 -38 10 14 21 18 35 14 18 -6 17 -4
-4 15 -27 24 -40 26 -45 9z"/>
      <path d="M4480 1990 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
      <path d="M3639 1963 c-9 -2 -22 -15 -29 -28 -7 -13 -22 -25 -34 -27 -15 -2
-21 -11 -23 -36 -2 -18 -10 -40 -18 -48 -8 -9 -15 -28 -14 -42 1 -15 -2 -35
-6 -44 -4 -11 -3 -18 3 -18 6 0 16 8 21 18 9 15 10 15 11 -4 0 -12 7 -25 16
-28 22 -8 21 -9 29 21 l6 26 19 -31 c11 -18 22 -32 24 -32 3 0 11 13 19 28 12
24 12 31 -1 50 -9 14 -11 22 -3 22 6 0 11 -4 11 -10 0 -14 27 -13 32 1 3 8 10
5 21 -6 21 -20 36 -13 18 9 -10 12 -8 16 9 21 24 8 19 17 -10 22 -11 2 -20 -2
-20 -9 0 -6 -3 -9 -6 -6 -3 4 0 16 6 28 8 15 8 20 1 16 -6 -4 -11 -2 -11 3 0
6 -9 11 -20 11 -17 0 -20 7 -21 43 -1 23 -1 45 0 50 1 7 -4 7 -30 0z"/>
      <path d="M3700 1912 c0 -5 6 -15 14 -21 12 -10 14 -9 12 5 -3 19 -26 33 -26
16z"/>
      <path d="M4407 1897 c-3 -8 3 -14 15 -14 16 -2 17 1 8 13 -15 17 -17 17 -23 1z"/>
      <path d="M3356 1883 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
      <path d="M3752 1891 c-11 -7 -11 -9 1 -14 16 -6 27 0 27 15 0 10 -11 10 -28
-1z"/>
      <path d="M4516 1849 c-11 -10 -17 -21 -14 -24 2 -3 15 6 28 20 27 29 14 33
-14 4z"/>
      <path d="M4436 1845 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
      <path d="M3337 1831 c-3 -14 -1 -18 9 -14 16 6 18 19 5 27 -5 3 -11 -3 -14
-13z"/>
      <path d="M4040 1834 c0 -3 7 -12 15 -20 16 -17 18 -14 9 10 -6 15 -24 23 -24
10z"/>
      <path d="M4385 1820 c-10 -11 -13 -20 -8 -20 13 0 38 29 31 35 -3 3 -13 -4
-23 -15z"/>
      <path d="M4471 1824 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
      <path d="M4080 1819 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M4420 1800 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
      <path d="M4347 1783 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
      <path d="M4446 1775 c-3 -8 -1 -15 3 -15 5 0 11 7 15 15 3 8 1 15 -3 15 -5 0
-11 -7 -15 -15z"/>
      <path d="M4023 1772 c-6 -4 -9 -19 -6 -34 3 -15 9 -25 15 -22 5 3 6 15 2 26
-4 13 -1 24 7 29 8 5 10 9 4 9 -5 0 -15 -4 -22 -8z"/>
      <path d="M4310 1744 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
      <path d="M3670 1701 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20 6z"/>
      <path d="M3545 1669 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z"/>
      <path d="M4201 1654 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
      <path d="M3290 1650 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
      <path d="M3475 1645 c-16 -7 -23 -14 -14 -14 8 -1 24 6 35 14 24 18 18 18 -21
0z"/>
      <path d="M3970 1650 c-8 -5 -11 -12 -7 -16 4 -4 13 -2 19 4 15 15 7 24 -12 12z"/>
      <path d="M3635 1600 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M3565 1589 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
      <path d="M3985 1590 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M4240 1579 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M4376 1581 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
      <path d="M4570 1539 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M3930 1515 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
      <path d="M4267 1519 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
      <path d="M3866 1501 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
      <path d="M3895 1480 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M3887 1431 c3 -12 9 -21 14 -21 14 0 11 16 -5 29 -12 10 -13 8 -9 -8z"/>
      <path d="M4440 1440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
      <path d="M3640 1409 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M3825 1410 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M3595 1381 c-17 -7 -16 -9 11 -13 24 -3 31 -1 27 9 -6 15 -9 15 -38
4z"/>
      <path d="M3805 1350 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
      <path d="M3620 1280 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
      <path d="M3580 1189 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M4220 1189 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
      <path d="M3740 1120 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
      <path d="M3706 1111 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
      <path d="M3616 1073 c-9 -9 -7 -33 3 -33 12 0 22 28 12 34 -5 3 -11 3 -15 -1z"/>
      <path d="M3475 1020 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M3563 1013 c-7 -3 -13 -12 -13 -22 0 -9 -7 -26 -15 -37 -20 -26 -19
-40 2 -23 15 13 48 78 41 84 -2 1 -9 0 -15 -2z"/>
      <path d="M4245 990 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M3662 965 c-7 -15 -11 -30 -8 -32 7 -7 28 36 24 49 -2 6 -9 -2 -16
-17z"/>
      <path d="M3453 963 c-18 -7 -16 -23 2 -23 8 0 15 7 15 15 0 8 -1 15 -2 14 -2
0 -9 -3 -15 -6z"/>
      <path d="M3980 900 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
      <path d="M3495 860 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
      <path d="M3846 851 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
      <path d="M3449 743 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
      <path d="M3330 700 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
      <path d="M3457 684 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23 -2
6 -7 8 -11 4z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: '7Days.vue',
};
</script>

<style scoped lang="sass">
.sevenDays
  fill: currentColor

</style>
