<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       width="1200.000000pt" height="1200.000000pt" viewBox="0 0 1200.000000 1200.000000"
       xml:space="preserve">
    <g class="custom-icon gmod"
       transform="translate(0.000000,1200.000000) scale(0.100000,-0.100000)">
    <path d="M1178 11989 c-455 -48 -886 -381 -1084 -839 -46 -105 -70 -202 -83
    -335 -8 -77 -11 -1476 -11 -4819 0 -5125 -3 -4866 55 -5041 124 -377 423 -700
    796 -862 96 -41 200 -67 334 -83 143 -16 9572 -9 9695 8 118 16 206 44 335
    108 319 158 565 420 698 744 45 109 64 185 73 285 4 44 9 2202 11 4795 3 3510
    1 4749 -7 4850 -23 265 -121 486 -321 725 -194 231 -524 424 -784 458 -97 12
    -9589 19 -9707 6z m4857 -1484 c378 -61 665 -185 926 -401 97 -80 220 -210
    295 -312 33 -43 69 -92 81 -108 l22 -29 0 358 1 357 805 0 805 0 -3 -3217 c-3
    -2945 -4 -3228 -20 -3332 -74 -506 -224 -883 -501 -1256 -100 -136 -338 -379
    -457 -467 -272 -203 -545 -343 -878 -452 -348 -114 -645 -163 -1051 -173 -767
    -18 -1413 146 -1948 497 -663 434 -1023 1059 -1072 1861 -5 90 -10 167 -10
    172 0 4 337 7 749 7 l750 0 5 -42 c3 -24 7 -77 10 -118 11 -164 86 -372 180
    -504 24 -34 86 -102 138 -151 141 -135 293 -216 503 -269 456 -115 888 -77
    1280 114 406 199 634 493 695 900 7 45 14 285 17 595 4 453 3 518 -10 505 -7
    -8 -51 -62 -97 -120 -219 -275 -431 -423 -762 -528 -258 -83 -479 -115 -783
    -115 -500 1 -866 75 -1220 248 -376 183 -692 455 -922 795 -353 521 -549 1324
    -530 2170 6 271 23 439 68 667 82 420 233 811 444 1153 183 296 467 597 745
    792 305 214 701 364 1083 413 147 18 521 13 662 -10z"/>
    <path d="M5945 9118 c-480 -28 -817 -241 -1015 -643 -100 -203 -165 -435 -195
    -691 -20 -172 -28 -604 -15 -763 75 -880 616 -1473 1345 -1473 357 0 660 131
    911 394 247 259 366 553 403 991 14 162 14 632 1 768 -25 249 -111 561 -210
    764 -150 306 -373 500 -687 599 -160 50 -323 66 -538 54z"/>
  </g></svg>
</template>

<script>
export default {
  name: 'GMod',
};
</script>

<style lang="sass">
.gmod
  fill: currentColor
</style>
