<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 708.661 708.661">
    <g class="custom-icon">
      <polygon
        points="642.345 578.908 642.345 129.101 479.085 578.908 642.345 578.908"/>
      <polygon
        points="409.009 661.266 572.727 211.459 489.134 211.459 325.419 661.266 409.009
                661.266"/>
      <polygon
        points="469.462 497.202 633.178 47.395 549.587 47.395 385.869 497.202 469.462
                497.202"/>
      <polygon
        points="315.368 578.908 479.085 129.101 395.492 129.101 231.777 578.908 315.368
                578.908"/>
      <polygon
        points="230.032 578.908 66.317 129.101 149.907 129.101 313.625 578.908 230.032
                578.908"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'VyHub',
};
</script>

<style scoped>

</style>
