<template>
  <svg class="rust" width="800px" height="800px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><rect class="a" x="23.3169" y="23.3169" width="3.7298" height="3.7298" transform="translate(-10.4307 25.1818) rotate(-45)"/><rect class="a" x="26.1241" y="27.9983" width="6.7484" height="3" transform="translate(-12.2186 29.4983) rotate(-45)"/><polygon class="a" points="34.56 29.788 29.788 34.56 35.698 42.5 42.5 35.698 34.56 29.788"/><rect class="a" x="21.8076" y="17.5774" width="6.7484" height="3" transform="translate(50.3637 38.1547) rotate(-180)"/><polygon class="a" points="21.808 15.294 28.556 15.294 29.992 5.5 20.372 5.5 21.808 15.294"/><rect class="a" x="15.7032" y="23.6818" width="6.7484" height="3" transform="translate(44.2592 6.1045) rotate(90)"/><polygon class="a" points="15.294 28.556 15.294 21.808 5.5 20.372 5.5 29.992 15.294 28.556"/></svg>
</template>

<script>
export default {
  name: 'Rust.vue',
};
</script>

<style scoped lang="sass">
.rust
  fill: currentColor
</style>
